<template>
  <div>
    <v-text-field
      :color="color"
      :label="labelFilter"
      :append-icon="postIcon"
      :prepend-icon="preIcon"
      filled
      v-model="localValue"
      :class="classes['switch']"
      :style="localStyle['switch']"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "FilterString",

  data: function () {
    let ls = { ...this.styles };

    return {
      localValue: this.value,
      localStyle: ls,
    };
  },
  props: {
    labelFilter: {
      type: String,
      default: "Active",
    },
    value: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    postIcon: {
      type: String,
      default: undefined,
    },
    preIcon: {
      type: String,
      default: undefined,
    },
    styles: {
      type: Object,
      default: function () {
        return {
          switch: {},
        };
      },
    },
    classes: {
      type: Object,
      default: function () {
        return {
          switch: {},
        };
      },
    },
  },
  watch: {
    localValue(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    value(val) {
      if (val !== this.localValue) this.localValue = val;
    },
  },
};
</script>

<style>
</style>